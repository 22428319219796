<template>
    <div class="goodsManage-wrapper">
        <div class="goodsMange-top">
            <div class="top-left">
                <span class="left-text">商品筛选</span>
                <el-select v-model="typeText" placeholder="请选择" size="small" @change="toggleType">
                    <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <el-button class="a-blue-btn" @click="addBtn">添加新商品</el-button>
        </div>
        <el-table :data="goodsList" style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="small"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#F6F5F8'}" header-cell-class-name="table-header-cell"
                  :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column prop="name" label="商品信息">
                <template slot-scope="scope">
                    <div class="good-info" style="padding-left: 30px">
                        <div class="cover">
                            <img :src="scope.row.goods_cover.src ? scope.row.goods_cover.src : require('../../assets/img/image/no-good-img.png')" alt="">
                        </div>
                        <div class="text-overflow title" :title="scope.row.goods_name">{{ scope.row.goods_name }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="价格" width="150">
                <template slot-scope="scope">
                    &yen; {{ scope.row.goods_price }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="上架时间" width="200">
                <template slot-scope="scope">
                    {{scope.row.issue_time ? scope.row.issue_time : '已下架'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row.id)">修改商品</el-link>
                    <el-link :underline="false" :type="scope.row.status === 1 ? 'info' : ''"
                             :class="scope.row.status === 0 ? 'link-blue' : ''"
                             @click="shelvesBtn(scope.row.id, scope.row.status)">{{scope.row.status === 0 ? '上架' : '下架'}}商品</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin-bottom: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                typeList: [
                    {
                        value: 2,
                        label: '全部',
                    },
                    {
                        value: 1,
                        label: '已上架',
                    },
                    {
                        value: 0,
                        label: '未上架',
                    },
                ],
                typeText: 2,
                goodsList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            if (this.typeList.length > 0) {
                this.typeText = this.typeList[0].value
            }
            this.getGoodsList()
        },
        methods: {
            // 添加商品
            addBtn() {
                this.$router.push('/admin/goodsManage/create')
            },
            // 筛选商品
            toggleType(val) {
                this.listPages.currentPageNum = 1
                this.getGoodsList()
            },
            // 获取商品列表
            getGoodsList() {
                let params = {
                    status: this.typeText,
                    type: 'page',
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.adminGoods_list, params, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.data.data
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 修改商品
            editBtn(id) {
                this.$router.push({
                    path: '/admin/goodsManage/create',
                    query: {
                        id: id
                    }
                })
            },
            // 上架商品
            shelvesBtn(id, status) {
                this.$confirm(`是否${status === 0 ? '上架' : '下架'}该商品`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    confirmButtonClass: 'a-blue-btn',
                }).then(() => {
                    let params = {
                        id: id,
                    }
                    if (status === 0) {
                        params.status = 1
                    } else if (status === 1) {
                        params.status = 0
                    }
                    this.$http.axiosGetBy(this.$api.adminGoods_putOrOut, params, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.getGoodsList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消上架');
                })
            },
            // 删除商品
            delBtn(id) {
                this.$confirm('是否删除该商品', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    confirmButtonClass: 'a-blue-btn',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.adminGoods_del, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message)
                            this.getGoodsList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .goodsManage-wrapper {
        display: flex;
        flex-direction: column;
    }
    .goodsMange-top {
        padding: 20px 40px 0;
        display: flex;
        align-items: center;
        .top-left {
            width: 1%;
            flex: 1;
            .left-text {
                font-size: 16px;
                margin-right: 12px;
            }
        }
    }
    .good-info {
        display: flex;
        align-items: center;
        .cover {
            width: 46px;
            height: 46px;
            display: flex;
            img {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }
        .title {
            width: 1%;
            flex: 1;
            margin-left: 16px;
        }
    }
    .el-link + .el-link {
        margin-left: 20px;
    }
    ::v-deep .table-header-cell {
        &:first-child {
            padding-left: 30px;
        }
    }
</style>